import React from 'react'
import { chechklistItemIcon, chechklistItemIconActive } from 'images'

class ChecklistItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = { active: false }
    this.toggleActive = this.toggleActive.bind(this)
  }

  toggleActive() {
    this.setState(state => ({
      active: !state.active,
    }))
  }

  render() {
    const { data, question, qdx } = this.props

    return (
      <div className="checklist-container" idx={qdx} key={qdx}>
        <div
          className={
            'checklist-item' +
            (this.state?.active ? ' checklist-item-active' : '')
          }
          onClick={this.toggleActive}
        >
          <div className="checklist-item-checkmark">
            <img
              src={
                this.state.active
                  ? chechklistItemIconActive
                  : chechklistItemIcon
              }
            />
          </div>
          <div className="checklist-item-description">
            <h5>{question.title} </h5>
            <p>
              {question.content}

              {question.islink === true ? (
                <a href={question.link} target="_blank" className="link blue">
                  Download hier het formulier.
                </a>
              ) : null}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default ChecklistItem
