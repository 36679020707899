import React, {useState} from 'react'
import { Link } from 'gatsby'
import CtaRow from 'components/cta-row'
import Helmet from 'react-helmet'
import Layout from '../../../layouts'
import ChecklistItem from 'components/checklist-item'


const allCategories = [
  {
    name: 'Welke gegevens heb je nodig van je nieuwe werknemer?',
    subtext: 'Subtext 1',
    id: 'title_1',
    questions: [
      {
        title: 'Naam & adresgegevens',
        id: 'hoe-lang-proefperiode',
        content: 'Houd de standaard persoonsgegevens bij zodat het duidelijk is wie er bij je komt werken.',
        link: '',
        islink: false
      },
      {
        title: 'Burgerservicenummer',
        id: 'hoe-start-ik',
        content: 'Als werkgever ben je verplicht om de identiteit van je werknemer vast te stellen.',
        link: '',
        islink: false
      },
      {
        title: 'Geboortedatum',
        id: 'hoe-start-ik',
        content: 'Ook de geboortedatum is nodig voor de aangifte bij de belastingdienst.',
        link: '',
        islink: false
      },
      {
        title: 'Bankrekeningnummer',
        id: 'hoe-start-ik',
        content: 'Niet onbelangrijk voor de werknemer, zijn bankrekeningnummer voor het salaris.',
        link: '',
        islink: false
      },
      {
        title: 'Kopie van ID-kaart of paspoort',
        id: 'meerdere-inlog',
        content: 'Je hebt een geldig legitimatiebewijs nodig ter bevestiging van de identiteit van je medewerker.',
        link: '',
        islink: false
      }
    ]
  },
  {
    name: 'Wat moet je verder doen voor je eerste werknemer?',
    subtext: 'Subtext 2',
    id: 'title_2',
    questions: [
      {
        title: 'Bepaal het soort contract',
        id: 'hoe-lang-proefperiode',
        content: 'Wat voor contract bied je aan, vast of oproep en voor hoelang?',
        link: '',
        islink: false
      },
      {
        title: 'Bepaal de hoogte van het salaris',
        id: 'meerdere-inlog',
        content: 'Vul het bedrag in wat je werknemer maandelijks gaat verdienen.',
        link: '',
        islink: false
      }
    ]
  },
  {
    name: 'Welke documenten moet je in orde maken?',
    subtext: 'Subtext 3',
    id: 'title_3',
    questions: [
      {
        title: 'Arbeidscontract',
        id: 'hoe-lang-proefperiode',
        content: 'Leg het arbeidscontract vast in overeenstemming met de medewerker.',
        link: '',
        islink: false
      },
      {
        title: 'Loonbelastingverklaring',
        id: 'meerdere-inlog',
        content: 'Als werkgever moet je loonheffingen inhouden op het loon van je werknemer. ',
        link: 'https://download.belastingdienst.nl/belastingdienst/docs/model_opgaaf_gegevens_loonheffingen_lh0082z7fol.pdf',
        islink: true
      }
    ]
  }

]


class onboardingChecklistPage extends React.Component {


  constructor(props) {
    super(props)


  }


  handleSubmit = event => {
    event.preventDefault();


  }


  render() {


    return(

      <Layout location={this.props.location}>
      <main className="tools animated fadeInPage">

        <Helmet>
          <title>Checklist nieuwe medewerker | Gratis HR Tool | Employes</title>
          <meta name="description" content="Bekijk wat je moet doen wanneer je een nieuwe werknemer aanneemt. Met deze handige tool ga je systematisch te werk en vink je één voor één alle to-do's af." />
          <meta itemprop="name" content="Handige tools salarisadministratie | Employes" />
          <meta itemprop="description" content="BBekijk wat je moet doen wanneer je een nieuwe werknemer aanneemt. Met deze handige tool ga je systematisch te werk en vink je één voor één alle to-do's af."/>
          <meta itemprop="image" content="/static/meta-tools.jpg" />

          {/* Windows tags */}
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content="static/favicon/mstile-144x144.png" />

          {/* OpenGraph tags */}
          <meta property="og:url" content="" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Checklist nieuwe medewerker | Gratis HR Tool | Employes" />
          <meta property="og:description" content="Bekijk wat je moet doen wanneer je een nieuwe werknemer aanneemt. Met deze handige tool ga je systematisch te werk en vink je één voor één alle to-do's af." />
          <meta property="og:image" content="https://employes.nl/static/meta-tools.jpg" />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Checklist werknemer aannemen| Gratis HR Tool | Employes" />
          <meta name="twitter:description" content="Bekijk wat je moet doen wanneer je een nieuwe werknemer aanneemt. Met deze handige tool ga je systematisch te werk en vink je één voor één alle to-do's af." />
          <meta name="twitter:image" content="https://employes.nl/static/meta-tools.jpg" />

        </Helmet>

        <header className="padding-xl">
          <div className="container-md">
            <div className="grid yg center text-center">
              <div className="col-8">
                <p className="eyebrow">Tool</p>
                <h1>Checklist <br /> nieuwe medewerker</h1>
                <p className="streamer center">Je hebt een nieuwe werknemer, top! We hebben een overzichtelijke checklist gemaakt, zodat jij alles in orde hebt en soepel van start kan gaan.</p>
              </div>
            </div>
          </div>
        </header>


        <section className="tools-cards padding-xxl">
          <div className="container-md">
            <div className="grid yg center">
              <div className="col-12 center">

                {allCategories.map((category, idx) => (

                  <div className="margin-l-bottom" id={category.id} key={idx}>

                    <div className="center text-center margin-s-bottom">
                      <h3>{category.name}</h3>
                    </div>
                    {category.questions.map((question, qdx) => (


                      <ChecklistItem
                        question={question}
                        idx={qdx}
                        key={qdx}
                      />

                      ))}
                  </div>
                ))}

              </div>

            </div>
          </div>
        </section>

      </main>
      </Layout>
    )
  }
}

export default onboardingChecklistPage
